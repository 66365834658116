import styled from 'styled-components';

export const Wrapper = styled.div`
  a {
    color: #000;
    text-decoration: none;
		

		@media (max-width: 960px) {
			color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
		}
  }

  ${({ desktop }) =>
    desktop
      ? `
			align-items: center;
			margin-left: 80px;
			padding-top: 43px;
			padding-right: 40px;
			display: flex;
			flex-direction: row;
			font-size: 16px;
			font-weight: 600;
			text-transform: uppercase;
			justify-content: space-between;

			@media (max-width: 960px) {
					display: none;
			}

			a {
					margin-right: 1rem;    
					position: relative;
    			text-decoration: none;

					&:hover:before {
						visibility: visible;
						-webkit-transform: scaleX(1);
						transform: scaleX(1);
							}
						&:before {
							content: "";
							position: absolute;
							width: 100%;
							height: 2px;
							bottom: 20px;
							left: 0;
							background-color: #000;
							visibility: hidden;
							-webkit-transform: scaleX(0);
							transform: scaleX(0);
							-webkit-transition: all .2s ease-in-out 0s;
							transition: all .2s ease-in-out 0s;

						}
					

					&:last-child {
							margin-right: unset;
					}
			}
		`
      : `
			padding: 3rem;
			display: flex;
			flex-direction: column;

			a {
					margin-bottom: 1rem;


					&:last-child {
							margin-bottom: unset;
					}
			}
	`}
`;
