import styled from 'styled-components';

export const Wrapper = styled.div`
  ${'' /* padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 35px!important;
  margin-left: 35px!important; */}
  position: absolute;
  overflow: visible;
  top: 0;
  left: 0;
  ${'' /* width: 98%; */}
  padding: 10px;
  display: flex;
  background-color: transparent;
  align-items: end;
  z-index: 10;
`;

export const Brand = styled.a`
  color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
  padding-left: 50px;

  @media (max-width: 960px) {
    mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    padding-left: 0;
  }
`;

export const Logo = styled.img`
  transition: .25s;
  ${Brand}:hover & {
    opacity: 0.6;
  }
`;