module.exports = {
  defaultTitle: 'Kumofy',
  logo: 'https://www.kumofy.com/favicon/favicon.svg',
  author: 'Kumofy',
  url: 'https://www.kumofy.com',
  legalName: 'Kumofy',
  defaultDescription: 'DevOps, software development and cloud construction team that solves real problems in real ways.',
  socialLinks: {
    twitter: '',
    github: '',
    linkedin: '',
    instagram: '',
    youtube: '',
    google: '',
  },
  googleUAID: 'UA-194403859-1',
  googleAnalyticsID: 'GTM-P76NXJB',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
    twitter: '@kumofy',
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2018',
};
